<template>

	<div class="generalBox" v-loading="loading" v-if="!IsXiTai">
		<div style="width:75%">
			<div class="flexRow" style="justify-content:space-between;height:180px">
				<el-card style="width:50%;height:100%">
					<div>
						<div class="flexRow" style="justify-content: space-between;position: relative;margin-top: -20px;">
							<div class="flexRow">
								<div style="width:55px;height:55px;border-radius:50%">
									<img :src="PcMallInfoResponse.MallLogoUrl?Imgurl+PcMallInfoResponse.MallLogoUrl:defaultLogo"
										style="width:55px;height:55px;border-radius:50%" alt="">
								</div>
								<div class="flexColumn"
									style="justify-content: space-between;padding:7px 0px;margin-left:10px;">
									<div style="margin-bottom: 0px;padding-top: 8px;">{{PcMallInfoResponse.MallName}}</div>
									<!-- <div style="font-size:14px;color:rgb(180,180,180)"
										v-if="PcMallInfoResponse.MallAddTime">
										创建时间:{{PcMallInfoResponse.MallAddTime.split(' ')[0]}}</div> -->
									<!-- 商城小程序 -->
									<div v-if="PcMallInfoResponse.IsAuthWxApp">
										<div class="flexRow" style="justify-content: space-between;"
											v-for="(v,i) in PcMallInfoResponse.WxAppList" :key="i"
											v-if="v.MicroProgramType == 0">
											<div class="font-Hidden allFont flexRow"
												style="color:#666;width:80%;height:25px;line-height:25px;margin-right: 3px;">
												<div class="justifyfont" style="width:80px;">商城小程序</div>
												<div>：
													<span>
														{{v.MicroProgramData.NickName}}
													</span>
												</div>
											</div>
											<el-button type="text" @click="jump(1)" v-if="v.WxappStatus ==1">
												有新版本,去提审</el-button>
											<el-button type="text" @click="jump(1)" v-if="v.WxappStatus ==2">
												新版本审核中</el-button>
											<el-button type="text" @click="jump(1)" v-if="v.WxappStatus ==3">
												审核失败,去查看</el-button>
											<el-button type="text" @click="jump(1)" v-if="v.WxappStatus ==4">
												审核通过,去发布</el-button>
											<el-button type="text" @click="jump(1)"
												v-if="v.WxappStatus ==5&&v.MicroProgramData.LatestVersion!=v.MicroProgramData.CurrentVersion">
												有新版本,去提审</el-button>
											<el-button type="text" style="color:rgb(180,180,180);cursor:default"
												v-if="v.WxappStatus ==5&&v.MicroProgramData.LatestVersion==v.MicroProgramData.CurrentVersion">
												已是最新版本</el-button>
										</div>
									</div>
									<div v-else>
										<div class="flexRow" style="justify-content: space-between;">
											<div class="font-Hidden allFont flexRow"
												style="color:black;width:80%;height:25px;line-height:25px">
												<div class="justifyfont" style="width:80px;">商城小程序</div>
												<div>：
													<span style="color:rgb(180,180,180)">
														未绑定
													</span>
												</div>
											</div>
											<el-button size="mini" type="text" @click="jump(1)">去绑定</el-button>
										</div>
									</div>
								</div>
							</div>
							<div class="shop-qrcode">
								<batch-cods2 :weiCodeVisivble="weiCodeShow2" :weiCodeData="weiCodeData2"
									@cancel="weiCodeCancel2" @clickshow="showmsg" @getpageshow="getpageshow">
								</batch-cods2>
								<!-- <el-button  type="text" style="position: relative;top: 50%;left:50%;transform: translate(-50%,-50%);">
									<svg t="1626677633770" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5095" width="12" height="12"><path d="M768 341.333333v341.333334c0 25.6-17.066667 42.666667-42.666667 42.666666s-42.666667-17.066667-42.666666-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666666-42.666666s42.666667 17.066667 42.666667 42.666666z m-213.333333 0v341.333334c0 25.6-17.066667 42.666667-42.666667 42.666666s-42.666667-17.066667-42.666667-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666667 42.666666zM341.333333 341.333333v341.333334c0 25.6-17.066667 42.666667-42.666666 42.666666s-42.666667-17.066667-42.666667-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666666 42.666666zM917.333333 106.666667h-204.8c-17.066667 0-34.133333-12.8-34.133333-34.133334s17.066667-29.866667 38.4-29.866666h234.666667c17.066667 0 29.866667 12.8 29.866666 34.133333v234.666667c0 17.066667-12.8 29.866667-34.133333 29.866666s-34.133333-12.8-34.133333-34.133333V106.666667zM106.666667 917.333333h204.8c17.066667 0 34.133333 12.8 34.133333 34.133334s-17.066667 29.866667-38.4 29.866666H76.8c-21.333333 0-34.133333-12.8-34.133333-34.133333v-234.666667c0-17.066667 12.8-29.866667 34.133333-29.866666s34.133333 12.8 34.133333 34.133333v200.533333z" fill="#409eff" p-id="5096"></path><path d="M917.333333 917.333333h-204.8c-17.066667 0-34.133333 12.8-34.133333 34.133334s12.8 34.133333 34.133333 34.133333h234.666667c17.066667 0 34.133333-12.8 34.133333-34.133333v-234.666667c0-17.066667-12.8-34.133333-34.133333-34.133333s-34.133333 12.8-34.133333 34.133333v200.533333zM106.666667 106.666667h204.8c17.066667 0 29.866667-12.8 29.866666-29.866667S328.533333 42.666667 307.2 42.666667H76.8c-21.333333 0-34.133333 12.8-34.133333 34.133333v234.666667c0 17.066667 12.8 29.866667 34.133333 29.866666s34.133333-12.8 34.133333-34.133333V106.666667z" fill="#409eff" p-id="5097"></path></svg>
									商城推广码
								</el-button> -->
							</div>
						</div>
						<div style="margin:5px 0px;display:flex;flex-direction:column;justify-content:center;color:#666">

							<div class="flexColumn"
								style="width:100%;height:85px;background:rgb(247,247,255);padding:5px 15px;">
								
								<!-- 微信服务号 -->
								<div class="flexRow" style="justify-content: space-between;">
									<div class="font-Hidden allFont flexRow"
										style="color:black;width:80%;height:25px;line-height:25px">
										<div class="justifyfont" style="width:80px;">微信服务号</div>
										<div>：
											<span v-if="!PcMallInfoResponse.IsAuthPublicPlatForm"
												style="color:rgb(180,180,180)">未绑定</span>
											<span v-else>
												{{PcMallInfoResponse.WxPublicPlatFormNickName}}
											</span>
										</div>
									</div>
									<!-- <el-button size="mini" type="text" v-if="!PcMallInfoResponse.IsAuthPublicPlatForm"
										@click="jump(16)">去绑定
									</el-button>
									<el-button size="mini" type="text" style="color:rgb(180,180,180);cursor:default"
										v-else>已绑定</el-button> -->
									<el-link type="primary" size="mini" :underline="false" style="font-size: 13px;" @click="jump(16)" v-if="!PcMallInfoResponse.IsAuthPublicPlatForm">去绑定</el-link>
									<el-link type="default" size="mini" :underline="false" style="color:rgb(180,180,180);cursor:default;font-size: 13px;" v-else>已绑定</el-link>
								</div>
								<!-- 企业微信 -->
								<div class="flexRow" style="justify-content: space-between;">
									<div class="font-Hidden allFont flexRow "
										style="color:black;width:80%;height:25px;line-height:25px;">
										<div class="justifyfont" style="width:80px;">企业微信</div>
										<div>：
											<span v-if="!PcMallInfoResponse.CorpName"
												style="color:rgb(180,180,180)">未绑定</span>
											<span v-else>
												{{PcMallInfoResponse.CorpName}}
											</span>
										</div>
									</div>
									<!-- <el-button size="mini" type="text" v-if="!PcMallInfoResponse.CorpName"
										@click="jump(8)">去绑定</el-button>
									<el-button size="mini" type="text" style="color:rgb(180,180,180);cursor:default"
										v-else>已绑定</el-button> -->
									<el-link type="primary" size="mini" :underline="false" style="font-size: 13px;" @click="jump(8)" v-if="!PcMallInfoResponse.CorpName">去绑定</el-link>
									<el-link type="default" size="mini" :underline="false" style="color:rgb(180,180,180);cursor:default;font-size: 13px;" v-else>已绑定</el-link>
								</div>

								<!-- 微信支付 -->
								<div class="flexRow" style="justify-content: space-between;">
									<div class="font-Hidden allFont flexRow "
										style="color:black;width:80%;height:25px;line-height:25px;">
										<div class="justifyfont" style="width:80px;">微信支付</div>
										<div>：
											<span v-if="!PcMallInfoResponse.IsApplyPay"
												style="color:rgb(180,180,180)">未开通</span>
										</div>
									</div>
									<el-link type="primary" size="mini" :underline="false" style="font-size: 13px;" @click="jump(17)" v-if="!PcMallInfoResponse.IsApplyPay">去开通</el-link>
									<el-link type="default" size="mini" :underline="false" style="color:rgb(180,180,180);cursor:default;font-size: 13px;" v-else>已开通</el-link>
									<!-- <el-button size="mini" type="text" v-if="!PcMallInfoResponse.IsApplyPay"
										>已开通</el-button>
									<el-button size="mini" type="text" style="color:rgb(180,180,180);cursor:default"
										v-else>已开通</el-button> -->
								</div>
							</div>
						</div>
					</div>
				</el-card>
				
				<el-card class="cardbody" style="width:50%;margin-left:10px;height:100%">
					<div class="tools-list">
						<div class="tools-item" @click="jump(15)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-selling-goods.png" alt="">
							<div class="item-box">
								<div class="txt">在售商品</div>
								<div class="count">{{PcMallInfoResponse.ProductCount}}</div>
							</div>
						</div>
						<!-- <router-link :to="{ path: '/order/orderMange', query: { OrderStateList: ['3'] } }"> -->
						<div class="tools-item" @click="jump(27)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-wait-delivery-order.png" alt="">
							<div class="item-box">
								<div class="txt">待发货订单</div>
								<div class="count">{{shopthingForm.WaitSendOrderCount}}</div>
							</div>
						</div>
						<!-- </router-link> -->
						<!-- <router-link :to="{ path: '/order/refundAfterSale/index', query: { IsWaitDeal: true } }"> -->
						<div class="tools-item" @click="jump(28)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-after-sales.png" alt="">
							<div class="item-box">
								<div class="txt">待处理售后</div>
								<div class="count">{{shopthingForm.WaitDealOrderSupportCount}}</div>
							</div>
						</div>
						<!-- </router-link> -->
						<div class="tools-item"  @click="jump(14)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-wait-Withdrawal-audit.png" alt="">
							<div class="item-box">
								<div class="txt">待审核提现</div>
								<div class="count">{{PcMallInfoResponse.WaitCheckWithDrawCount}}</div>
							</div>
						</div>
						<div class="tools-item" @click="jump(23)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-wait-distribution.png" alt="">
							<div class="item-box">
								<div class="txt">待配送</div>
								<div class="count">{{shopthingForm.PendingDeliveryOrderCount}}</div>
							</div>
						</div>
						<div class="tools-item" @click="jump(24)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-wait-order.png" alt="">
							<div class="item-box">
								<div class="txt">待接单</div>
								<div class="count">{{shopthingForm.WaitTakeOrderCount}}</div>
							</div>
						</div>
						<div class="tools-item" @click="jump(25)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-wait-claim-goods.png" alt="">
							<div class="item-box">
								<div class="txt">待取货</div>
								<div class="count">{{shopthingForm.WaitPickUpOrderCount}}</div>
							</div>
						</div>
						<div class="tools-item" @click="jump(26)" style="cursor:pointer">
							<img src="https://cdn.dkycn.cn/melyshop/image/home-abnormal.png" alt="">
							<div class="item-box">
								<div class="txt">配送异常</div>
								<div class="count">{{shopthingForm.DeliveryErrorOrderCount}}</div>
							</div>
						</div>
					</div>
				</el-card>
				
			</div>
			<!-- 实时数据 -->

			<button-permissions :datas="'IndexingData'" :Type="1">
				<el-card style="margin-top:10px" v-loading="dataloading">
					<div class="TopItem CommonItems" style="border:none">
						<div class="displayCommon title">
							<p>实时数据</p>
							<div style="margin-left: 15px;cursor: pointer;" @click="getrealTimedata">
								<svg-icon icon-class="refreshIcon" />
							</div>

							<el-link type="primary" style="margin-left: 20px;" @click="jumpMore">查看更多数据</el-link>
						</div>
						<div class="TimeData displayCommon DataCommon">
							<div class="DataItem">
								<div class="Title">支付订单数</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.PayOrderCount}}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.PayOrderCount != PrevRealTimeData.PayOrderCount
												? CurrentRealTimeData.PayOrderCount > PrevRealTimeData.PayOrderCount
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.PayOrderCount }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">支付金额</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.PayOrderMoney }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.PayOrderMoney != PrevRealTimeData.PayOrderMoney
												? CurrentRealTimeData.PayOrderMoney > PrevRealTimeData.PayOrderMoney
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.PayOrderMoney }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">客单价</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.CustomerUnitPrice }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.CustomerUnitPrice != PrevRealTimeData.CustomerUnitPrice
												? CurrentRealTimeData.CustomerUnitPrice > PrevRealTimeData.CustomerUnitPrice
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.CustomerUnitPrice }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">访问-支付转化率</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.UVAndPayOrderMemberCountConversionRate + '%' }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.UVAndPayOrderMemberCountConversionRate != PrevRealTimeData.UVAndPayOrderMemberCountConversionRate
												? CurrentRealTimeData.UVAndPayOrderMemberCountConversionRate > PrevRealTimeData.UVAndPayOrderMemberCountConversionRate
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.UVAndPayOrderMemberCountConversionRate + '%' }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">浏览量</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.PV }}</span>
									<svg-icon
										:icon-class="CurrentRealTimeData.PV != PrevRealTimeData.PV ? (CurrentRealTimeData.PV > PrevRealTimeData.PV ? 'gorise' : 'decline') : ''" />
								</div>
								<div>昨日：{{ PrevRealTimeData.PV }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">访客数</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.UV }}</span>
									<svg-icon
										:icon-class="CurrentRealTimeData.UV != PrevRealTimeData.UV ? (CurrentRealTimeData.UV > PrevRealTimeData.UV ? 'gorise' : 'decline') : ''" />
								</div>
								<div>昨日：{{ PrevRealTimeData.UV }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">支付人数</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.PayOrderMemberCount }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.PayOrderMemberCount != PrevRealTimeData.PayOrderMemberCount
												? CurrentRealTimeData.PayOrderMemberCount > PrevRealTimeData.PayOrderMemberCount
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.PayOrderMemberCount }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">新增会员数</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.NewVipCount }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.NewVipCount != PrevRealTimeData.NewVipCount
												? CurrentRealTimeData.NewVipCount > PrevRealTimeData.NewVipCount
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.NewVipCount }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">退款成功单数</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.RefundOrderCount }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.RefundOrderCount != PrevRealTimeData.RefundOrderCount
												? CurrentRealTimeData.RefundOrderCount > PrevRealTimeData.RefundOrderCount
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.RefundOrderCount }}</div>
							</div>
							<div class="DataItem">
								<div class="Title">退款成功金额</div>
								<div class="Middle displayCommon">
									<span>{{ CurrentRealTimeData.RefundOrderMoney }}</span>
									<svg-icon :icon-class="
											CurrentRealTimeData.RefundOrderMoney != PrevRealTimeData.RefundOrderMoney
												? CurrentRealTimeData.RefundOrderMoney > PrevRealTimeData.RefundOrderMoney
													? 'gorise'
													: 'decline'
												: ''
										" />
								</div>
								<div>昨日：{{ PrevRealTimeData.RefundOrderMoney }}</div>
							</div>
						</div>
					</div>
				</el-card>
			</button-permissions>

			<!-- 销售助手 -->
			<el-row>
				<el-col :span="16">
					<el-card style="margin-top:10px">
						<div style="font-size:16px;font-weight:bold">销售助手</div>
						<div style="margin-top:20px;">
							<el-tabs v-model="activeName" type="card" @tab-click="clickTab">
								<el-tab-pane label="商城流量数据" name="first">
									<div v-if="trafficStatistical.CurrentFlowOverview">
										<div style="font-size:14px;color:rgb(180,180,180)"
											v-if="trafficStatistical.CurrentFlowOverview.UV>trafficStatistical.PrevFlowOverview.UV">
											近7天商城访客{{trafficStatistical.CurrentFlowOverview.UV}}人，较前7天增长{{num}}，继续努力哦~
										</div>
										<div style="font-size:14px;color:rgb(180,180,180)"
											v-else-if="trafficStatistical.CurrentFlowOverview.UV<trafficStatistical.PrevFlowOverview.UV">
											近7天商城访客{{trafficStatistical.CurrentFlowOverview.UV}}人，较前7天下降{{num}}，需要加强店铺引流哦~
										</div>
										<div style="font-size:14px;color:rgb(180,180,180)"
											v-else-if="trafficStatistical.CurrentFlowOverview.UV==trafficStatistical.PrevFlowOverview.UV">
											近7天商城访客{{trafficStatistical.CurrentFlowOverview.UV}}人，较前7天保持不变
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="急需补货商品" name="second">
									<div style="font-size:14px;color:#999;line-height:37px" class="flexRow">
										急需补货商品：当前库存少于10个的商品
										<router-link to="/goods/remindArrival">
											<el-button style="margin-left:25px" type="text">查看全部</el-button>
										</router-link>
									</div>
									<div>
										<div class="flexRow product-item" v-for="(v,i) in lackList" :key="i"
											style="margin-top:15px">
											<div style="width:60px;height:60px;">
												<img :src="Imgurl+v.ImgUrl" style="wdith:100%;height:100%" alt="">
											</div>
											<div class="font-Hidden ImgFont">{{v.Name}}</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="可能积压商品" name="third">
									<div style="font-size:14px;color:#999;line-height:37px" class="flexRow">
										可能积压商品：当前上架销售并且近30天销量为0的商品
										<router-link
											:to="{ path: '/goods/goodsList', query: { IsRecentUnsalableProduct: true } }">
											<el-button style="margin-left:25px" type="text">查看全部</el-button>
										</router-link>
									</div>
									<div>
										<div class="flexRow product-item" v-for="(v,i) in overflow" :key="i"
											style="margin-top:15px">
											<div style="width:60px;height:60px;">
												<img :src="Imgurl+v.ImgUrl" style="wdith:100%;height:100%" alt="">
											</div>
											<div class="font-Hidden ImgFont">{{v.Name}}</div>
										</div>
									</div>
					
								</el-tab-pane>
							</el-tabs>
						</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card style="margin-top:10px;margin-left: 10px;">
						<div style="width:100%;text-align:center">
							<img src="@/assets/img/gh_6b0dc94e5182_860.jpg" style="width:180px;height:180px;margin:10px auto 15px"
								alt="">
						</div>
						<div style="font-size:24px;text-align: center;margin-bottom: 15px;">商城数据 实时查看</div>
						<div style="font-size: 16px;color: #999;text-align: center;line-height: 20px;">
							扫码使用企店数据助手
						</div>
					</el-card>
				</el-col>
			</el-row>
			
		</div>
		<div style="width:25%;margin-left:10px">
			
			<el-card class="version-info">
				<div class="version-name">企店{{PcMallInfoResponse.VersionName}}</div>
				<div class="expire-time" v-if="PcMallInfoResponse.VersionLevel && PcMallInfoResponse.ExpireTime">
					到期时间：{{PcMallInfoResponse.ExpireTime.split(' ')[0]}}
					<el-tag type="info" size="mini" style="margin-left: 10px;" v-if="PcMallInfoResponse.IsExpire">已到期</el-tag>
				</div>
				<div class="account-info">
					<div class="account-info-item">
						<div>商城账户余额(元)：</div><div class="account-info-item-count" @click="jump(11)">{{realBalanceInfo.Balance}}</div>
					</div>
					<div class="account-info-item">
						<div>免费订单额度(笔)：</div><div class="account-info-item-count" @click="jump(11)">{{realBalanceInfo.FreeOrderCount}}</div>
					</div>
					<div class="account-info-item">
						<div>短信数量(条)：</div><div class="account-info-item-count" @click="jump(20)">{{realBalanceInfo.SMSCount}}</div>
					</div>
					<div class="account-info-item">
						<div>货款(元)：</div><div class="account-info-item-count" @click="jump(12)">{{realBalanceInfo.SupplierBalance}}</div>
					</div>
					<div class="account-info-item">
						<div>累计奖励(元)：</div><div class="account-info-item-count" @click="jump(21)">{{realBalanceInfo.RecommendRewards}}</div>
					</div>
					<el-link type="info" class="link" style="line-height:1.5" @click="jump(22)">
						资费说明 <i class = "el-icon-question" style="cursor: pointer;"></i>
					</el-link>
				</div>
				<div class="server-info" v-if="PcMallInfoResponse.PaymentRuleModelList" v-for="(item, index) in PcMallInfoResponse.PaymentRuleModelList" :key="index">
					<img src="https://cdn.dkycn.cn/images/melyshop/pc-index-start-current.png" v-if="item.IsOpen && !item.IsExpire">
					<img src="https://cdn.dkycn.cn/images/melyshop/pc-index-start-gray.png" v-else>
					<div class="name">
						{{item.RuleName}}
						<div class="state" v-if="item.IsOpen && !item.IsExpire && item.ExpireTime">{{item.ExpireTime.split(' ')[0]}}到期</div>
						<div class="state" v-else-if="item.IsOpen && item.IsExpire">已到期</div>
						<div class="state" v-else-if="!item.IsOpen">未开通</div>
					</div>
					<el-link class="open" type="primary" style="line-height: 20px;height: 20px;" @click="handleToBuy(item.Id)" v-if="!item.IsOpen">开通</el-link>
					<el-link class="open" type="primary" style="line-height: 20px;height: 20px;" @click="handleToBuy(item.Id)" v-else>续费</el-link>
				</div>
				<div class="to-open" @click="handleToOpen">
					<el-link class="open" type="primary" style="font-size: 14px;" v-if="PcMallInfoResponse.VersionLevel">续费</el-link>
					<el-link class="open" type="primary" style="font-size: 14px;" v-else>升级</el-link>
				</div>
			</el-card>
			
			<!-- 功能查找 -->
			<!-- <el-card>
				<div class="flexRow" style="width:120px;height:40px;margin:0px auto;cursor: pointer;"
					@click="BtnFunquery">
					<div style="width:40px;height:40px;">
						<img src="https://cdn.dkycn.cn/melyshop/image/20210722-1f9839e8-8d31-44bc-b6dc-d23f8e60ff0d.png"
							alt="" />
					</div>
					<div style="line-height:40px;margin-left:10px">
						功能查找
					</div>
				</div>
			</el-card> -->
			<!-- 联系企店客服 -->
			<!-- <el-card style="margin-top:10px">
				<el-popover placement="bottom" trigger="hover" content="">
					<div class="kefu-pop">
						<div class="tip1">添加专属客服</div>
						<div class="tip2">一对一为您答疑解惑</div>
						<img src="../../assets/img/20210719171848.jpg" style="width:150px;height:150px" alt="">
					</div>

					<div class="flexRow" style="width:180px;margin:0px auto;padding-left:30px" slot="reference">
						<div style="width:40px;height:40px">
							<img src="https://cdn.dkycn.cn/melyshop/image/20210722-72bb4c6b-5330-4e85-9358-c357c62de71f.png"
								alt="" />
						</div>
						<div style="line-height:40px;margin-left:10px;cursor: pointer;">
							联系企店客服
						</div>
					</div>
				</el-popover>

			</el-card> -->
			<!-- 帮助中心 PM文档还没写完，先隐藏掉 -->
			<!-- <el-card style="margin-top:10px">
				<div class="flexRow" style="width:120px;margin:0px auto;cursor:pointer" @click="jump(13)">
					<div style="width:40px;height:40px">
						<img src="https://cdn.dkycn.cn/melyshop/image/20210722-d7b3aec7-603d-496c-aaf2-dff02d2ce994.png"
							alt="" />
					</div>
					<div style="line-height:40px;margin-left:10px">
						帮助中心
					</div>
				</div>
			</el-card> -->
			<!-- 系统公告 -->
			<el-card style="margin-top:10px">
				<div class="flexRow" style="justify-content: space-between;margin-bottom:15px">
					<div style="font-size:14px;font-weight:bold">系统公告</div>
					<div style="font-size:14px;color:rgb(150,150,150);cursor: pointer;" @click="playAllnews">全部>>>
					</div>
				</div>
				<div style="line-height:30px;width:100%" class="flexRow" v-for="(v,i) in noticeList" :key="i">
					<div style="width:10px">
						<div style="width:6px;height:6px;background:black;border-radius:50%;margin-top:12px"></div>
					</div>
					<div style="width:100%;overflow:hidden">
						<span v-if="v.IsNew"
							style="color:rgb(245,108,108);font-size:14px;font-weight:bold;float:left">new</span>
						<a :href="v.Href" target="_blank" style="display:block;">
							<div class="announcementFont">
								【{{v.DemandTypeString}}】{{v.Message}}
							</div>
						</a>
					</div>
				</div>
			</el-card>
			
		</div>




		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel" :title='"商城推广码"'>
		</batch-cods>

		<el-dialog :visible.sync="imgUrlVisi" width="700px" class="eidt_info" v-loading='loading'>
			<div style="text-align: center;">
				<img src="http://cdn.dkycn.cn/Images/phonePcimg.png" style="width: 344px;" />
			</div>
		</el-dialog>
		<!-- ec5d4a -->
		<!-- 页面地址 -->
		<el-dialog :visible.sync="pageShow" title="商城页面小程序码" width="600px">
			<el-table :data="pagetable" max-height="600px">
				<el-table-column prop="name" label="页面名称">
					<template slot-scope="scope">
						{{scope.row.name}} <span style="color: #999;font-size: 12px;padding-left: 5px;"
							v-if="scope.row.desc">{{scope.row.desc}}</span>
					</template>
				</el-table-column>
				<el-table-column>
					<template slot-scope="scope">
						<div style="width:100%;text-align:right">
							<batch-cods2 :icon="false" :weiCodeVisivble="weiCodeShow2" :weiCodeData="weiCodeData2"
								@cancel="weiCodeCancel2" @clickshow="showmsgtable(scope.row)" :text="'小程序码'"
								:input="true"></batch-cods2>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:100%;text-align:right;margin-top:15px">
				<el-button @click="pageShow = false">关闭</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="funquery" width="70%" @close="funqueryClose">
			<div>
				<div><i class="el-icon-search" style="font-size:16px">搜索全部导航</i></div>
				<el-input v-model="searchKeyWords" class="fun-input" placeholder="输入菜单名称,按下回车搜索" @change="searchKW">
				</el-input>
				<div class="fun-search-font search-rul" style="margin-bottom:10px">搜索结果：{{searchArr.length}}个</div>
				<!-- <div class="fun-search-font search-over" style="margin-left:30px;" v-for="(v,i) in searchArr" :key="i">{{generateTitle(v.meta.title)}}</div> -->
				<!-- <div style="display:flex;flex-direction:column"> -->
				<div v-for="(v,i) in searchArr" :key="i" style="margin:5px 0px 0px 20px">
					<el-button class="search-over" style="border:none" @click="BtnJump(v)">
						{{generateTitle(v.meta.title)}}
					</el-button>
				</div>
				<!-- </div> -->
			</div>
			<div style="margin-top:50px">
				<div><i class="el-icon-map-location" style="font-size:16px">搜索全部导航</i></div>
				<el-tabs v-model="searchNum" style="margin-top:10px">
					<el-tab-pane :label="'1'" v-for="(v,i) in pageList" :key="i" :name="String(i+1)">
						<div class="scrollbar">
							<div class="search-rules-tit" style="float:left;height:100%;display:inline-block">
								<div style="text-align:center">
									<el-button
										style="border:none;cursor:default;background:white;color:black;font-size:16px;">
									</el-button>
								</div>
								<div style="text-align:center;">
									<el-button class="search-rules-mainFont" style="font-size:16px;padding:10px 15px"
										@click="BtnJump(x)"></el-button>
								</div>
								<div style="width:100%;margin-top:10px">
									<div style="text-align:center">
										<el-button class="search-rules-mainFont" @click="BtnJump()"></el-button>
									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>

		<functional-nav :visible="showFunctionNav" @close="showFunctionNav=false">
			123456
		</functional-nav>

	</div>
</template>

<script>
	import batchCods from '@/components/bathCodes';
	import batchCods2 from '@/components/bathCodes2';
	import {
		mapGetters
	} from 'vuex';
	import buttonPermissions from '@/components/buttonPermissions';
	import BarChart from '@/components/Charts/mixChart';
	import config from '@/config/index'
	import apiList from '@/api/other'

	import functionalNav from './components/functionalNav.vue';

	import {
		generateTitle
	} from '@/utils/i18n'
	import {
		Indexrealtinedata,
		IndexShoptings,
		Indextrendsdata,
		realTimeBalanceInfo,
		realTimeSystemAffiche,
		mallDataInfo,
		pcBaseInfo
	} from '@/api/TurnTomySelf';
	import {
		productList
	} from "@/api/goods"
	import {
		interestProductPageInit
	} from "@/api/sv1.0.0"
	import {
		resetloginpassword
	} from '../../api/login';
	export default {
		components: {
			BarChart,
			batchCods,
			batchCods2,
			buttonPermissions,
			functionalNav
		},
		data() {
			return {
				defaultLogo: 'https://cdn.dkycn.cn/melyshop/image/20210722-9726909c-ec6e-450d-b45b-43dcbaea5e8d.png',
				imgUrlVisi: false,
				isaddProduct: false, //发布商品
				isorderMange: false, //订单管理
				ismallNavconfig: false, //商城配置
				isCustomerSearch: false, //客户查询
				isshippingTemplates: false, //添加运费模板
				isstaffMange: false, //添加员工
				iskeyproline: false, //一键铺货
				isinquiry: false, //推手中心
				IsprogramPlay: false,
				number: 1,
				weiCodeData: {},
				weiCodeShow: false,
				loading: false,
				currentIndex: 1,
				userNumber: {},
				//昨日数据
				CurrentRealTimeData: {},
				PrevRealTimeData: {},
				shopthingForm: {},
				realBalanceInfo: {},
				IsOpenMallBalance: false, //是否开启服务费
				IsOpenSupplierProduct: false, //是否开启供货市场
				noticeList: [],
				goUrls: config.GO_URL,
				Imgurl: config.IMG_BASE,

				activeName: 'first',
				PcMallInfoResponse: {},
				trafficLoading: false,
				trafficStatistical: {},
				num: '',
				lackList: [], //急需补货的商品
				overflow: [], //可能积仓的商品
				funquery: false, //功能查找
				searchKeyWords: '',
				searchNum: '1',
				showRoutes: [],
				weiCodeShow2: false,
				weiCodeData2: {},
				pageShow: false,
				pagetable: [],
				pageList: [],
				searchArr: [],
				dataloading: false,

				showFunctionNav: false
			};
		},
		watch: {
			functionalNavChange(bol) {
				this.getpageshow()
			}
		},
		computed: {
			...mapGetters(['ShopmallName', 'routerList', 'permission_routers', 'addRouters', 'permission_routers',
				'allRouters', 'functionalNavChange', 'IsAdmin','IsXiTai'
			])
		},
		methods: {
			jumpnewPage(e) {
				console.log(e)
				// window.open(e.Href)
			},
			generateTitle,
			gettoday() {
				let time = new Date()
				let nowtime = new Date()
				let beforetime = new Date(time.getTime() - 1000 * 60 * 60 * 24 * 7)
				let year = nowtime.getFullYear()
				let month = nowtime.getMonth() + 1 >= 10 ? nowtime.getMonth() + 1 : '0' + (nowtime.getMonth() + 1)
				let day = nowtime.getDate() >= 10 ? nowtime.getDate() : '0' + nowtime.getDate()
				let hour = nowtime.getHours() >= 10 ? nowtime.getHours() : '0' + nowtime.getHours()
				let min = nowtime.getMinutes() >= 10 ? nowtime.getMinutes() : '0' + nowtime.getMinutes()
				let sec = nowtime.getSeconds() >= 10 ? nowtime.getSeconds() : '0' + nowtime.getSeconds()
				let beforeyear = beforetime.getFullYear()
				let beforemonth = beforetime.getMonth() + 1 >= 10 ? beforetime.getMonth() + 1 : '0' + (beforetime
					.getMonth() + 1)
				let beforeday = beforetime.getDate() >= 10 ? beforetime.getDate() : '0' + beforetime.getDate()
				// console.log(beforeyear,beforemonth,beforeday)
				// console.log(year,
				// 	month,
				// 	day,
				// 	hour,
				// 	min,
				// 	sec,
				// 	beforeyear,
				// 	beforemonth,
				// 	beforeday)
				return {
					year,
					month,
					day,
					hour,
					min,
					sec,
					beforeyear,
					beforemonth,
					beforeday
				}
			},
			async getdata() {
				let time = this.gettoday()
				let data = {
					ClientType: 1,
					EndDate: time.year + '-' + time.month + '-' + time.day + ' ' + time.hour + time.min + time.sec,
					StartDate: time.beforetime + '-' + time.beforemonth + '-' + time.beforeday + ' ' + '00:00:00',
					TimeType: 0,
				}
				// console.log(data)
				let result = await apiList.dataStatisFlowOverview(data);
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.CurrentRealTimeData = result.Result.CurrentFlowOverview;
					this.PrevRealTimeData = result.Result.PrevFlowOverview;
				}

			},
			async getdata2() {
				let time = this.gettoday()
				let data = {
					ClientType: 1,
					EndDate: time.year + '-' + time.month + '-' + time.day + ' ' + time.hour + time.min + time.sec,
					StartDate: time.year + '-' + time.month + '-' + time.day + ' ' + '00:00:00',
					TimeType: 0,
				}
				let result = await apiList.dataStatisFlowReferer(data);
				if (result.IsSuccess) {
					// console.log(result.Result)

					// this.CurrentRealTimeData = result.Result.CurrentFlowOverview;
					// 	this.PrevRealTimeData = result.Result.PrevFlowOverview;
				}
			},
			showmsg() {
				this.weiCodeData2 = {
					Page: `pages/index/index`,
					Scene: '1'
				};
			},
			//页面地址小程序码
			showmsgtable(e) {
				// console.log(e)
				let path = ''
				if (e.id == 1) { // 全部商品
					path = 'pages/index/index'
				} else if (e.id == 2) {
					path = 'pages/allProduct/allProduct'
				} else if (e.id == 3) { // 个人中心
					path = 'pages/personalCenter/mine/mine'
				} else if (e.id == 4) { // 购物车
					path = 'pages/shoppingCart/shoppingCart'
				} else if (e.id == 5) { // 积分中心
					path = 'pages/pointsCenter/pointsCenter'
				} else if (e.id == 6) { // 每日签到
					path = 'pages/userSign/userSign'
				} else if (e.id == 7) { // 客服
					path = 'pages/customerService/customerService'
				} else if (e.id == 8) { // 订单列表
					path = 'pages/order/myOrder/myOrder'
				} else if (e.id == 9) { // 分销礼包
					path = 'pages/invitation/invitationCard/invitationCard'
				} else if (e.id == 10) { // 成为会员
					path = 'pageA/pages/openSuccess/openSuccess'
				} else if (e.id == 11) { // 助力直播列表
					path = 'pageA/pages/live/live'
				} else if (e.id == 12) { // 扫码购
					path = 'pageA/pages/scan/scan'
				} else if (e.id == 13) { // 充值中心
					path = 'pageA/pages/balanceRecharge/balanceRecharge'
				} else if (e.id == 14) { // 群分享
					path = 'pageA/pages/groupShare/groupShare'
				} else if (e.id == 15) { // 群分享
					path = 'pageA/pages/information/information'
				}else if (e.id == 20) { // 门店列表
					path = 'pages/choseDoor/choseDoor'
				}
				this.weiCodeShow2 = true;
				this.weiCodeData2 = {
					Page: path,
					Scene: '1'
				};
			},
			//打开页面地址
			getpageshow() {
				this.pageShow = true
				this.pagetable = [{
						id: 1,
						name: '商城小程序码',
						desc: '客户扫码进入商城'
					},
					{
						id: 2,
						name: '全部商品列表'
					},
					{
						id: 3,
						name: '个人中心页'
					},
					{
						id: 4,
						name: '购物车'
					},
					{
						id: 5,
						name: '积分中心'
					},
					{
						id: 6,
						name: '日历签到'
					},
					{
						id: 7,
						name: '联系客服'
					},
					{
						id: 8,
						name: '全部订单列表'
					},
					{
						id: 9,
						name: '推手招募页'
					},
					{
						id: 10,
						name: '会员开通'
					},
					{
						id: 11,
						name: '助力直播列表'
					},
					{
						id: 12,
						name: '扫码购'
					},
					{
						id: 13,
						name: '余额充值'
					},
					{
						id: 20,//这里取的是选择链接系统页面的
						name: '门店列表'
					}
				]
				// console.log(this.pagetable)
			},
			//关闭导航
			funqueryClose() {
				this.searchKeyWords = '',
					this.searchArr = []
			},
			//打开导航
			BtnFunquery() {

				this.showFunctionNav = true;
				// let arr = JSON.parse(JSON.stringify(this.addRouters))
				// // console.log(arr)
				// this.pageList = arr.filter((v)=>{
				// 	if(!v.hidden){
				// 		if(v.children!=undefined&&v.children.length>0){
				// 			v.children = v.children.filter((x)=>{
				// 				if(!x.hidden){
				// 					if(!x.children){
				// 			x.islast = true
				// 			// return x
				// 		}else if(x.children!=undefined&&x.children.length>0){
				// 						x.children = x.children.filter((m)=>{
				// 							if(!m.hidden){
				// 								return m
				// 							}
				// 						})
				// 					}
				// 					return x
				// 				}
				// 			})
				// 			return v
				// 		}

				// 	}
				// })
				// let arr = JSON.parse(JSON.stringify(this.allRouters))
				// this.pageList = arr.filter((v)=>{
				// 	//一级不需要判断按钮
				// 	if(v.ChildMenuDtoList!=undefined&&v.ChildMenuDtoList.length>0){
				// 		v.ChildMenuDtoList = v.ChildMenuDtoList.filter((x)=>{
				// 			//二级也不需要
				// 			if(x.ChildMenuDtoList!=undefined&&x.ChildMenuDtoList.length>0){
				// 				x.ChildMenuDtoList = x.ChildMenuDtoList.filter((m)=>{
				// 					//第三级判断是否为按钮  
				// 					if(!m.IsButton){
				// 						return m
				// 					}
				// 				})
				// 				return x
				// 			}
				// 		})
				// 		return v
				// 	}
				// })
			},
			//搜索关键字
			searchKW() {
				this.searchArr = []
				this.pageList.map((v) => {
					v.children.map((x) => {
						//如果是最后一级 也加入搜索 不是再搜索他的子级
						if (x.islast && this.generateTitle(x.meta.title).indexOf(this.searchKeyWords) >=
							0) {
							this.searchArr.push(x)
						} else if (x.children != undefined && x.children.length > 0) {
							x.children.map((m) => {
								if (this.generateTitle(m.meta.title).indexOf(this
										.searchKeyWords) >= 0) {
									this.searchArr.push(m)
								}
							})
						}
					})
				})
			},
			//导航跳转
			BtnJump(e) {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + e.path
				window.open(url)
			},
			//新店开张跳转
			jump(e) {
				if (e == 7) {
					// console.log(e)
					this.weiCodeShow = true;
					this.weiCodeData = {
						Page: `pages/index/index`,
						Scene: '1'
					};
				} else if (e == 9) {
					window.open('https://jusnn6k8al.feishu.cn/docs/doccn8RmCaATDogYQ8ZNGtwEjFe')
				} else if (e == 13) {
					window.open('https://jusnn6k8al.feishu.cn/docs/doccnmkLV4OvgRFsHf6iNN3lJBL')
				} else if (e == 22) {
					window.open('https://jusnn6k8al.feishu.cn/docs/doccnFOro82Lv6RCtcQTAJ19f9c')
				}else if([23,24,25,26,27].includes(e)){
					let name = 'orderMange'
					let	params = {
								type:e
							}
					this.$router.push({
						name: name,
						params:params
					})
				}else if([28].includes(e)){
					let name = 'refundAfterSale'
					let	params = {
								type:e
							}
					this.$router.push({
						name: name,
						params:params
					})
				} else {
					let route = ''
					if (e == 1) {
						route = '/setting/wxAppManage' //微信小程序管理页
					} else if (e == 2) {
						route = '/setting/settingPay' //支付设置页
					} else if (e == 3) {
						route = '/goods/creatGoodDetails?Id=0' //商品添加页
					} else if (e == 4) {
						route = '/setting/index' //设置页
					} else if (e == 5) {
						route = '/market/coupon/index' //优惠券管理列表页
					} else if (e == 6) {
						route = '/mallPage/mallPageDetails?id=0&isGroup=shop' //自定义页-首页-页面装修编辑页
					} else if (e == 8) {
						route = '/saleChannel/qyWechat' //企业微信管理页
					} else if (e == 10) {
						route = '/Customer/tagLibrary' //标签库管理列表页
					} else if (e == 11) {
						route = '/assets/myAccount' //服务费账户页
					} else if (e == 12) {
						route = '/assets/suplatAccount' //供货账户页
					} else if (e == 14) {
						var mod = localStorage.getItem('mlmzDistributionModel')
						if (mod === '0') {
							route = '/distribution/assets/withDrawal' //提现中心列表页
						} else {
							route = '/agent/assets/withDrawal' //提现中心列表页
						}
					} else if (e == 15) {
						route = '/goods/goodsList' //商品列表页
					} else if (e == 16) {
						route = '/setting/setOfficialAccount' //公众号管理页
					} else if (e == 17) {
						route = '/setting/settingPay' //支付设置
					} else if (e == 20) {
						route = '/smsaccount/smsaccountindex' //短信数量
					} else if (e == 21) {
						if (!this.IsAdmin) {
							this.$message.error('仅超级管理员可查看该页面 ')
							return
						}
						route = '/assets/myrecommended/index' //短信数量
					}
					// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
					// let url = this.goUrls + headsUrls + route
					// window.open(url)
					this.$router.push({
						path: route,
					})
				}
			},
			// isadmintojump(){
			// 	if(this.IsAdmin){
			// 		this.$router.push({
			// 			path: '/assets/myrecommended/index'
			// 		})
			// 	}else{
			// 		this.$message.error('暂无【推荐奖励】功能权限')
			// 	}
			// },
			calculate(num1, num2) {
				// console.log(((7-3)/7*100).toFixed(2))
				let num = ''
				if (num1 == 0 || num2 == 0) {
					num = 100
				} else {
					num = ((num1 - num2) / num2 * 100).toFixed(2)
				}
				return num + '%'
			},
			//商城数据
			async getpcBaseInfo() {
				this.loading = true
				// let result = await pcBaseInfo({})
				// if(result.IsSuccess){

				// 	this.PcMallInfoResponse = result.Result;
				// 	console.log(this.$store.commit, 'getpcBaseInfo')
				// 	this.$store.commit('SET_IsShowUpdateTips', result.Result.IsShowUpdateTips)
				// }


				this.$store
					.dispatch('GetPcShopInfo')
					.then((data) => {
						// console.log(data)
						this.PcMallInfoResponse = data.Result;
						this.loading = false
					})
					.catch(e => {
						this.loading = false
					});
			},
			//tabs  1
			async gettraffic() {
				this.trafficLoading = true
				let time = this.gettoday()
				try {

					let StartDate = time.beforeyear + '-' + time.beforemonth + '-' + time.beforeday + ' ' + '00:00:00'
					let EndDate = time.year + '-' + time.month + '-' + time.day + ' ' + '00:00:00'
					// +' '+this.gettoday().hour+':'+this.gettoday().min+':'+this.gettoday().sec
					let data = {
						TimeType: 4,
						StartDate: StartDate,
						EndDate: EndDate,
						ClientType: 1
					}
					let result = await apiList.dataStatisFlowOverview(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.trafficStatistical = result.Result
						if (result.Result.CurrentFlowOverview.UV > result.Result.PrevFlowOverview.UV) {
							this.num = this.calculate(result.Result.CurrentFlowOverview.UV, result.Result
								.PrevFlowOverview.UV)
						} else {
							this.num = this.calculate(result.Result.PrevFlowOverview.UV, result.Result
								.CurrentFlowOverview.UV)
						}
					}
					// console.log(data)
				} finally {
					this.trafficLoading = false
				}
			},
			//tabs  2
			async getData() {
				this.trafficLoading = true
				let result = await apiList.productSubscribeList({
					Skip: 0,
					Take: 2,
				})
				// console.log(result)
				this.lackList = result.Result.Results
				// this.tableList = result.Result.Results
				// this.page.total = result.Result.Total

				this.trafficLoading = false
			},
			//tabs  3
			async getproductList() {
				this.trafficLoading = true
				try {
					let data = {
						IsRecentUnsalableProduct: true,
						Skip: 0,
						Take: 2,
					}
					let result = await productList(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.overflow = result.Result.Results
					}
				} finally {
					this.trafficLoading = false
				}
			},
			//点击tabs
			clickTab(e) {
				console.log(e.name)
				if (e.name == 'first') {
					this.gettraffic()
				} else if (e.name == 'second') {
					this.getData()
				} else if (e.name == 'third') {
					this.getproductList()
				}
			},



			//手机版后台
			phoneweiCode() {
				this.imgUrlVisi = true
			},
			getRouterList() {
				if (this.routerList.some(item => {
						return item == 'addProduct'
					})) {
					this.isaddProduct = true
				}
				if (this.routerList.some(item => {
						return item == 'orderMange'
					})) {
					this.isorderMange = true
				}
				if (this.routerList.some(item => {
						return item == 'mallNavconfig'
					})) {
					this.ismallNavconfig = true
				}
				if (this.routerList.some(item => {
						return item == 'CustomerSearch'
					})) {
					this.isCustomerSearch = true
				}
				if (this.routerList.some(item => {
						return item == 'inquiry'
					})) {
					this.isinquiry = true
				}
				if (this.routerList.some(item => {
						return item == 'shippingTemplates'
					})) {
					this.isshippingTemplates = true
				}

				if (this.routerList.some(item => {
						return item == 'staffMange'
					})) {
					this.isstaffMange = true
				}
				if (this.routerList.some(item => {
						return item == 'keyproline'
					})) {
					this.iskeyproline = true
				}

			},
			//系统消息
			playAllnews() {
				this.$router.push({
					path: '/SystemNews/SystemNewsIndex'
				});
			},
			itemNews(item) {
				let Id = item.Id ? item.Id : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/needDetail?Id=' + Id
				window.open(url)
				// this.$router.push({
				// 	path: '/needDetail',
				// 	query:{
				// 		Id:item.Id
				// 	}
				// });
			},
			// `id=${ProductId}`
			// 推广
			weiCodeFun() {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/index/index`,
					Scene: '1'
				};
				// if (!this.IsprogramPlay) {
				// 	this.weiCodeShow = true;
				// 	this.weiCodeData = {
				// 		Page: `pages/index/index`,
				// 		Scene: '1'
				// 	}
				// } else {
				// 	this.$message({
				// 		showClose: true,
				// 		message: '商城小程序还未发布上线，推广码生成失败',
				// 		type: 'error'
				// 	});
				// }
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			weiCodeCancel2(value) {
				this.weiCodeShow2 = value;
			},
			getShopmallName() {
				let data = {};
				this.$store
					.dispatch('GetPcShopInfo', data)
					.then(() => {})
					.catch(e => {});

				this.$store
					.dispatch('GetPcMallName', {})
					.then(() => {})
					.catch(e => {});
			},
			//近7天
			recentSevent() {
				this.currentIndex = 1;
				this.getTrensData();
			},
			//近30天
			recentThirty() {
				this.currentIndex = 2;
				this.getTrensData();
			},
			async getTrensData() {
				// this.loading = true;
				try {
					let data = {
						TimeType: this.currentIndex
					};
					let result = await Indextrendsdata(data);

					if (result.IsSuccess) {
						let chartData = result.Result.map(item => {
							item.Date = item.Date.split(' ')[0];
							return item;
						});
						let newdata = {};
						newdata.DateList = [];
						newdata.CountList = [];
						newdata.MoneyList = [];
						chartData.forEach(item => {
							newdata.DateList.push(item.Date);
							newdata.CountList.push(item.PayOrderCount); //支付订单数
							newdata.MoneyList.push(item.PayOrderMoney); //支付金额
						});
						this.userNumber = newdata;
					}
				} catch (err) {} finally {
					// this.loading = false;
				}
			},
			refreshrealTime() {
				this.getrealTimedata();
			},
			refreshShop() {
				this.getIndexShoptings();
			},
			async getrealTimedata() {
				try {
					this.dataloading = true;
					let result = await Indexrealtinedata({});
					if (result.IsSuccess) {
						this.CurrentRealTimeData = result.Result.CurrentRealTimeOverview;
						this.PrevRealTimeData = result.Result.PrevRealTimeOverview;
					}
				} catch (err) {} finally {
					this.dataloading = false;
				}
			},
			async getIndexShoptings() {
				try {
					// this.loading = true;
					let result = await IndexShoptings({});
					if (result.IsSuccess) {
						this.shopthingForm = result.Result;
						// console.log(this.shopthingForm)
					}
				} catch (err) {} finally {
					// this.loading = false;
				}
			},
			async getRealBalanceInfo() {
				try {
					// this.loading = true;
					let result = await realTimeBalanceInfo({});
					if (result.IsSuccess) {
						this.realBalanceInfo = result.Result;
						// console.log(this.realBalanceInfo)
					}
				} catch (err) {} finally {
					// this.loading = false;
				}
			},
			async getSystemNoticeList() {
				try {
					// this.loading = true;
					let result = await realTimeSystemAffiche({});
					if (result.IsSuccess) {
						this.noticeList = result.Result;
						// console.log(this.noticeList)
					}
				} catch (err) {} finally {
					// this.loading = false;
				}
			},
			async getmallInfo() {
				try {
					let result = await mallDataInfo({});
					this.IsOpenMallBalance = result.Result.IsOpenMallBalance || false;

					this.IsOpenSupplierProduct = result.Result.IsOpenSupplierProduct || false;
				} catch (err) {} finally {}
			},

			jumpMore() {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/data/businessAnalysis'
				window.open(url)
			},
			
			handleToOpen(){
				// console.log(localStorage.getItem('MallId'))
				this.$router.push({
					path: '/versionOrder',
					// query:{
					// 	MallId:localStorage.getItem('MallId')
					// }
				})
			},
			handleToBuy(id){
				this.$router.push({
					path: '/buyRule',
					query:{
						id: id
					}
				})
			}
		},
		created() {
			if(this.IsXiTai) return 
			// console.log()
			// 验证是否为免费版
			this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
				// 重新获取路由
				this.$store.dispatch('GenerateRoutes').then(() => {})
			})
			this.IsOpenMallBalance = JSON.parse(window.localStorage.getItem('IsOpenMallBalance'));
			this.IsOpenSupplierProduct = JSON.parse(window.localStorage.getItem('IsOpenSupplierProduct'));
			// this.getdata()
			// this.getdata2()
			this.getpcBaseInfo()

			this.getmallInfo();
			//系统消息
			this.getSystemNoticeList();
			// //实时概览-商城账户
			this.getRealBalanceInfo();
			// //获取实时数据
			this.getrealTimedata();

			this.gettraffic()


			// //商城事项
			this.getIndexShoptings();
			// //趋势图
			// this.getTrensData();

			// this.getShopmallName();
			// this.getRouterList()
		}
	};
</script>

<style lang="less" scoped>
	.link:hover{
		color:#409eff;
	}
	.version-info{
		position: relative;
		line-height: 1;
		font-size: 14px;
		padding-top: 10px;
		
		.to-open{
			position: absolute;
			right: 30px;
			top: 30px;
		}
		
		.version-name{
			font-size: 20px;
			
		}
		.expire-time{
			font-size: 14px;
			margin-top: 10px;
		}
		.account-info{
			background-color: #F7F7FF;
			padding: 10px 15px;
			margin-bottom: 20px;
			margin-top: 20px;
			
			.account-info-item{
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #666;
				height: 35px;
				
				.account-info-item-count{
					color: #333;
					font-weight: bold;
					cursor: pointer;
				}
				.account-info-item-count:hover{
					color:#409eff
				}
			}
		}
		.server-info{
			margin-bottom: 26px;
			display: flex;
			overflow: hidden;
			
			img{
				flex: 0 0 auto;
				width: 20px;
				height: 18px;
				margin-right: 14px;
			}
			.name{
				flex: 1 1 auto;
				overflow: hidden;
				margin-right: 20px;
				
				.state{
					font-size: 12px;
					color: #999;
					margin-top: 5px;
				}
			}
			.open{
				flex: 0 0 auto;
			}
		}
	}
	
	.shop-qrcode{
		position: absolute;
		right: 0;
		top: 5px;
	}
	
	.tools-list{
		height: 100%;
		
		display: flex;
		align-items: center;
		// justify-content: space-around;
		flex-wrap: wrap;
		
		.tools-item{
			display: flex;
			// flex-direction: column;
			align-items: center;
			// justify-content: center;
			flex-shrink: 0;
			width: 25%;
			margin: 10px 0px;
			
			img{
				width: 30px;
				height: 30px;
			}
			.item-box{
				margin-left: 10px;
				.txt{
					font-size: 14px;
					color: #666;
					line-height: 1.5;
					white-space: nowrap;
					overflow: hidden;
					margin-top: 4px;
				}
				
				.count{
					font-size: 16px;
					font-weight: bold;
					line-height: 1.5;
				}

			}
		}
	}
	
	//新添加的样式
	.warn_Content {
		background: #fdf6ec;
		border: 1px solid #fda23c;
		padding: 15px 0 15px 10px;
		font-size: 13px;
		color: #fda23c;
		margin-bottom: 10px;

	}

	.shopNewbox {
		justify-content: space-between;
		margin-bottom: 10px;
		font-size: 14px;

		.shopNewLeft {
			background: #fff;
			padding: 15px;
			// box-sizing: border-box;
			flex: 1;
			height: 150px;
			font-size: 14px;
		}

		.shopNewRight {
			background: #fff;
			height: 150px;
			padding: 15px;
			flex: 1;
			// box-sizing: border-box;

			.linkCommon {
				align-items: center;
				// width: 100%;

				.circle_Common {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: #000000;
				}

				::v-deep .el-link--inner {
					display: flex;
					align-items: center;
					width: 100%;
				}

				.flexCommon {
					width: 70px;
					word-break: break-all;
				}

				.overflowCommon {
					overflow: hidden;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
					-webkit-text-overflow: ellipsis;
					-moz-text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	//
	.displayCommon {
		display: -webkit-box;
		/* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
		display: -moz-box;
		/* Firefox 17- */
		display: -webkit-flex;
		/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
		display: -moz-flex;
		/* Firefox 18+ */
		display: -ms-flexbox;
		/* IE 10 */
		display: flex;
		// justify-content: space-between;
		/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
	}

	.EchartBox {
		.tab_box {
			width: 180px;
			height: 35px;
			line-height: 35px;
			border: 1px solid #ddd;
			font-size: 14px;
			cursor: pointer;

			.Item {
				width: 50%;
				text-align: center;
				background: #f0f2f5;
			}

			.IsActive {
				background: #fff;
				color: #409eff;
			}
		}
	}

	.CommonItems {
		background: #fff;
		padding: 10px;
		border: 1px solid #ddd;
		margin-bottom: 15px;

		.title {
			align-items: center;
			font-size: 14px;
		}

		.DataCommon {
			width: 92%;
		}
	}

	.UsuallyBox {
		padding-bottom: 30px;

		.UsualItems {
			display: flex;
			font-size: 15px;
			margin: 0px auto;
			flex-wrap: wrap;

			.UsualItem {
				width: 12%;
				flex-direction: column;
				margin-top: 25px;
				justify-content: center;

				a {
					display: block;
					width: 100%;
				}

				&:last-child {
					margin-right: 0;
				}

				.Icons {
					margin-bottom: 10px;
					text-align: center;

					.specilIcon {
						width: 1.5rem !important;
						height: 1.5rem !important;
					}
				}

				// align-items: center;
			}
		}
	}

	.generalBox {
		display: flex;
		flex-direction: row;

		.TopItem {
			.TimeData {
				margin: 20px auto 0px;
				flex-wrap: wrap;

				.DataItem {
					width: 25%;
					margin-bottom: 40px;
					font-size: 14px;

					.Title {
						color: #666;
					}

					.Middle {
						margin: 10px 0;
						font-weight: bold;
						font-size: 20px;
					}
				}
			}
		}
	}

	.ShopItem {
		.title {
			align-items: center;
		}

		.ShopData {
			margin: 20px auto;
			flex-wrap: nowrap;

			.DataItem {
				width: 20%;
				font-size: 14px;

				.Title {
					color: #666;
				}

				.Middle {
					margin-top: 20px;
					font-weight: bold;
					color: #ec5d4a;
					font-size: 18px;
				}
			}
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		// align-items: center;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
		// justify-content: space-around;
		// justify-content: space-between;
		// justify-content: space-evenly;
	}

	.font-Hidden {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.allFont {
		font-size: 13px;
		line-height: 36px;
	}

	.justifyfont {
		text-align: justify;
		text-align-last: justify;
	}

	.justifyfont:after {
		display: inline-block;
		width: 100%;
	}

	// .fourBox {
	// 	width: 49%;
	// 	height: 100%;
	// 	// border:1px solid black;
	// 	background: rgb(247, 247, 255);

	// 	.fourBox-contentBox {
	// 		position: relative;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translate(-50%, -50%);
	// 		width: 60%;
	// 		// border:1px solid black;
	// 	}
	// }

	.ImgFont {
		margin-left: 10px;
		width: 500px;
		// border:1px solid black;
		line-height: 60px
	}

	::v-deep .el-drawer {
		background: rgba(0, 0, 0, 0.3)
	}
</style>
<style scoped lang="less">
	.scrollbar {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 30px;
		white-space: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.fun-input ::v-deep .el-input__inner {
		border-radius: 40px;
		margin-top: 15px
	}

	.fun-search-font {
		font-size: 14px;
		margin: 10px 0px 0px 20px
	}

	.search-rul {
		font-size: 16px;
		margin: 20px 0px 0px 20px
	}

	.search-rules-tit {
		/* border:1px solid black; */
		width: 130px;
	}

	.search-rules-titFont {
		font-size: 16px;
	}

	.search-rules-mainFont {
		border: none
	}

	.search-rules-mainFont:hover {
		background: #409eff;
		color: white;
	}

	.announcementFont {
		font-size: 14px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		/* border:1px solid black */
	}

	.announcementFont:hover {
		color: #409eff
	}

	.search-over {
		font-size: 14px;
	}

	.search-over:hover {
		background: #409eff;
		color: white;
	}

	.product-item {
		padding-bottom: 15px;
		border-bottom: 1px dashed #ddd;

		&:last-child {
			border: none;
		}
	}

	.help-left {
		flex: 1 1 auto;
		overflow: hidden;
	}

	.help-right {
		flex: 0 0 auto;
		width: 400px;
		height: 300px;
		margin-left: 10px;

		display: flex;
		flex-wrap: wrap;

		.fourBox {
			flex: 0 0 auto;
			width: 48%;
			margin-bottom: 10px;
			margin-right: 2%;
			background-color: #f7f7ff;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	@media screen and (max-width: 1200px) {
		.help-right {
			width: 350px;
			height: 160px;
		}
	}

	.kefu-pop {
		text-align: center;
		font-size: 14px;
		color: #333;

		.tip1 {
			font-weight: bold;
			margin-bottom: 3px;
		}

		.tip2 {
			color: #999;
			margin-bottom: 5px;
		}
	}
</style>
<style lang="less" scoped>
	.datenum {
		color: #303133;
		font-weight: bold;
		text-align: center;
		cursor: pointer;
		font-size: 20px
	}

	.datenum:hover {
		color: #409eff
	}

	.datafont {
		font-size: 14px;
		color: #666;
		white-space: nowrap;
	}

	.cardbody ::v-deep .el-card__body {
		height: 100%;
	}

	.iconbox {
		width: 15px;
		height: 15px;
		// border:1px solid red;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}

	.iconbox:hover {
		color: #409eff;
	}

	.shopLeftdetail {

		display: flex;
		flex-direction: row;
		align-items: flex-start;
		color: #999;
		width: 100%;
		// height:100%;
		flex-wrap: wrap;
		padding-top: 20px;
		// border:1px solid black;
		justify-content: space-between;
	}

	.detailItem {
		margin: 0px 0px 24px 0px;
		height: 70px;
		//这里宽度要算上每行第一个的margin
		width: calc((100% - 15px) / 3);
		// align-items: center;
		// border:1px solid black;
		align-items: flex-start;
	}

	.detailItem:nth-child(3n+1) {
		margin-left: 15px
	}

	.detailItem:last-child {
		margin-right: auto;
	}
</style>
