<template>
	<div>

    <el-popover placement="bottom"  width="350" trigger="click" >
      <div v-loading="codeding">
        <div class = "flexRow" v-if="input">
					<div style="width:85px;text-align:right">小程序路径：</div>
          <el-input v-model="InputvalueNew" style="flex:1" disabled></el-input>
					<el-button v-clipboard:copy="InputvalueNew" v-clipboard:success="onCopy" v-clipboard:error="onError">
						复制
					</el-button>
        </div>
				<div class = "flexRow" v-if="input" style="margin-top:20px">
					<div style="width:85px;text-align:right;">短信链接：</div>
          <el-input v-model="smslink" style="flex:1" disabled></el-input>
					<el-button v-clipboard:copy="smslink" v-clipboard:success="onCopy" v-clipboard:error="onError">
						复制
					</el-button>
        </div>
				
        <div v-if="proState == -1">
          <div style="text-align:right" v-if="!input">
            <el-button type="text" @click="getpageshow">其他页面小程序码</el-button>
          </div>
          <img :src="imgUrl+codePicture" alt="" id="codeId"
            style="width:200px;display: block;margin:20px auto">
          <div style="text-align:center;margin-top: 25px;">
            <span style="color:#409EFF;cursor:pointer;padding:10px 0;display: inline-block; "
              @click="downloadIamge('img',codePicture)">{{downFont}}</span>
          </div>
        </div>
        <div v-else-if="proState == 0">
          <div style="color:rgb(245,108,108);font-size:14px">你还未授权绑定商城小程序,商城小程序码生成失败
            <el-button type="text" style="margin-left:10px" @click="jump(1)">点击去绑定</el-button>
          </div>
          <div style="margin-top:20px;font-size:12px">
            你还可以扫描下方小程序码,先行体验哦~
          </div>
          <div style="width:130px;height:130px;margin:10px auto">
            <img src="https://cdn.dkycn.cn/melyshop/image/20210723-e99b7cfd-403a-42bc-8cb0-8bad39819548.png" style="width:100%;height:100%" alt="">
          </div>
        </div>
        <div v-else-if="proState == 1">
          <div style="color:rgb(245,108,108);font-size:14px">你还未发布商城小程序,商城小程序码生成失败
            <el-button type="text" style="margin-left:10px" @click="jump(2)">点击去发布</el-button>
          </div>
        </div>
			</div>

      <el-button  slot="reference" :type="buttontype" @click="callparentClick">
        <svg v-if="icon" t="1626677633770" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5095" width="12" height="12"><path d="M768 341.333333v341.333334c0 25.6-17.066667 42.666667-42.666667 42.666666s-42.666667-17.066667-42.666666-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666666-42.666666s42.666667 17.066667 42.666667 42.666666z m-213.333333 0v341.333334c0 25.6-17.066667 42.666667-42.666667 42.666666s-42.666667-17.066667-42.666667-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666667 42.666666zM341.333333 341.333333v341.333334c0 25.6-17.066667 42.666667-42.666666 42.666666s-42.666667-17.066667-42.666667-42.666666V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666666 42.666666zM917.333333 106.666667h-204.8c-17.066667 0-34.133333-12.8-34.133333-34.133334s17.066667-29.866667 38.4-29.866666h234.666667c17.066667 0 29.866667 12.8 29.866666 34.133333v234.666667c0 17.066667-12.8 29.866667-34.133333 29.866666s-34.133333-12.8-34.133333-34.133333V106.666667zM106.666667 917.333333h204.8c17.066667 0 34.133333 12.8 34.133333 34.133334s-17.066667 29.866667-38.4 29.866666H76.8c-21.333333 0-34.133333-12.8-34.133333-34.133333v-234.666667c0-17.066667 12.8-29.866667 34.133333-29.866666s34.133333 12.8 34.133333 34.133333v200.533333z" fill="#409eff" p-id="5096"></path><path d="M917.333333 917.333333h-204.8c-17.066667 0-34.133333 12.8-34.133333 34.133334s12.8 34.133333 34.133333 34.133333h234.666667c17.066667 0 34.133333-12.8 34.133333-34.133333v-234.666667c0-17.066667-12.8-34.133333-34.133333-34.133333s-34.133333 12.8-34.133333 34.133333v200.533333zM106.666667 106.666667h204.8c17.066667 0 29.866667-12.8 29.866666-29.866667S328.533333 42.666667 307.2 42.666667H76.8c-21.333333 0-34.133333 12.8-34.133333 34.133333v234.666667c0 17.066667 12.8 29.866667 34.133333 29.866666s34.133333-12.8 34.133333-34.133333V106.666667z" fill="#409eff" p-id="5097"></path></svg>
          {{text}}
      </el-button>
  </el-popover>


	</div>
</template>

<script>
	import apiList from '@/api/other';
	import config from '@/config/index'
	import axios from "axios"
	import {
		mallFreeVersionConfigInfo
	} from "@/api/goods"
	import {
		wxappwxappgenerateurllink
	} from "@/api/sv3.0.0"
	export default {
		props: {
			weiCodeData: {
				type: Object
			},
			Num: {
				type: Number,
			},
			downFont:{
				type:String,
				default:'下载小程序码'
			},
			buttontype:{
				type: String,
				default:'text'
			},
			title: {
				type: String,
				default: () => {
					return '微信小程序'
				}
			},
			weiCodeVisivble: {
				type: Boolean,
				default: () => {
					return false
				}
			},
      icon:{
				type:Boolean,
				default:true
			},
      text:{
        type:String,
        default:'商城小程序码'
      },
      input:{
        type:Boolean,
        default:false,
      },
		},
		created() {
			this.weiCodeIshow = this.weiCodeVisivble
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				goUrls: config.GO_URL,
				codePicture: 'https://cdn.dkycn.cn/melyshop/image/20191222-492d658e-4654-4778-a097-9e439e9de307.png',
				inputValue: '',
				weiCodeIshow: false,
				codeding: false,
				InputvalueNew: '',
				mallConfigInfo:{},
        proState:-1,

				smslink:'',
			}
		},
		watch: {
			weiCodeVisivble(n, o) {
				this.weiCodeIshow = n
			},
			weiCodeData(n, o) {
				// console.log(n,1212)
				this.inputValue = this.weiCodeData.Scene == 1 ? `${this.weiCodeData.Page}` :
					`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`
				this.InputvalueNew = this.weiCodeData.InputVaule ? this.weiCodeData.InputVaule : this.inputValue
				// this.inputValue =this.weiCodeData.Scene?`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`:`${this.weiCodeData.Page}`
				this.getData()

			},
		},
		methods: {
      getpageshow(){
        this.$emit('getpageshow')
      },
      callparentClick(){
        this.$emit('clickshow')
      },
      jump(e){
        let route = ''
        if(e == 1){
          route = '/setting/wxAppManage'
        }else if(e == 2){
          route = '/setting/wxAppManage'
        }else if(e == 2){
          route = '/setting/wxAppManage'
        }

        let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + route
				window.open(url)

      },
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
					// console.log(this.mallConfigInfo)
					window.open(this.mallConfigInfo.WxAppAuthUrl)
			},
			getRamNumber() {
				var result = '';
				for (var i = 0; i < 16; i++) {
					result += Math.floor(Math.random() * 16).toString(16);
				}
				return result.toUpperCase();
			},
			async getData() {
				try {
					this.codeding = true
					// this.weiCodeIshow = false
					let data = {
						Page: this.weiCodeData.Page,
						Scene: this.weiCodeData.Scene,
						IsLimited: false,
						ProgramType: 0,
						noError: true
					}
					// console.log(data)
					let result = await apiList.mallGetQrCode(data)
					// console.log(result, 'getData')
					// result.ErrorCode = 85074
					// console.log(result.ErrorCode)

					if (result.ErrorCode == 0) {
						this.weiCodeIshow = true
						this.codePicture = JSON.parse(result.Result)[0]
						this.proState = -1
						// console.log(this.codePicture)
            // this.proState = 0
					} else {
						this.codePicture = 'https://cdn.dkycn.cn/melyshop/image/20191222-492d658e-4654-4778-a097-9e439e9de307.png'
						this.weiCodeIshow = false
						if (result.ErrorCode == 1000063) { //未绑定商城小程序
							this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
								this.signLoding = false;
								this.state = window.localStorage.getItem('mlmzFreePayState')
								// this.state = 0
								// console.log(window.localStorage.getItem('mlmzFreePayState'))
								if (this.state == 0) {
                  this.proState = 0
									// this.$confirm('请先绑定商城小程序', '提示', {
									// 	distinguishCancelAndClose: true,
									// 	confirmButtonText: '去绑定',
									// 	cancelButtonText: '关闭'
									// }).then(() => {
									// 	this.getMallConfig()
									// }).catch(action => {});
									// return
								}
								window.localStorage.setItem('canEditSetinfos', true);
								window.localStorage.setItem('toPayResultFromPath', this.$route.path);
							}).catch(() => {})
						} else if (result.ErrorCode == 85074) { //小程序未发布
            	this.proState = 1
							// this.$confirm('商城小程序还未发布上线，推广码生成失败', '', {
							// 	confirmButtonText: '去发布',
							// 	cancelButtonText: '关闭',
							// }).then(() => {
							// 	let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
							// 	let url = this.goUrls + headsUrls + '/setting/wxAppManage'
							// 	window.open(url)
							// }).catch(() => {})
						} else if (result.ErrorCode == 41030) { //page页面不存在
            	this.proState = 2
							// this.$confirm('获取新页面推广码，请先将小程序版本更新成最新版本', '', {
							// 	confirmButtonText: '去更新',
							// 	cancelButtonText: '关闭',
							// }).then(() => {
							// 	let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
							// 	let url = this.goUrls + headsUrls + '/setting/wxAppManage'
							// 	window.open(url)
							// }).catch(() => {})
						}
					}
					//  console.log(this.weiCodeData.Page,1)

					let secondParam = window.localStorage.getItem('mlmzMallKey')
					let url = ''
					if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
						url =`http://wapapi.qidian.shop/jump.html?path=${encodeURIComponent(data.Page)}&query=${encodeURIComponent(data.Scene)}&mallkey=` + secondParam
					} else {
						url =`http://wap.dkyapitest.cn/jump.html?path=${encodeURIComponent(data.Page)}&query=${encodeURIComponent(data.Scene)}&mallkey=` + secondParam
					}
					let res2 = await axios.post('http://api.xsmore.com:80/short/link/',{Url:url,R:true})
					if(res2.status==200){
						this.smslink = res2.data.Url
					}else{
						this.$message.error(res2.data.Message)
					}
				} catch (e) {
					console.log('catch', e)
				} finally {
					this.codeding = false
				}
			},
			async jumplink() {
				let result = await mallFreeVersionConfigInfo()
				// console.log(result)
				return result
			},
			comeback() {
				this.$emit('cancel', false)
			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

			downloadIamge(selector, name) {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}

				image.src = document.getElementById('codeId').src


			},
		}
	}
</script>

<style lang="less">
	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
