<template>
	<el-dialog :visible="visible" width="1200px" @close="funqueryClose">
		<div>
			<div style="font-size: 16px;"><i class="el-icon-search" style="font-size:16px;margin-right: 5px;"></i>搜索全部导航</div>
			<el-input v-model="searchKeyWords" class="fun-input" placeholder="输入菜单名称,按下回车搜索" @change="searchKW">
			</el-input>
			<div class="fun-search-font search-rul" v-if="issearch" style="margin-bottom:10px">搜索结果：{{searchArr.length}}个</div>
			<!-- <div class="fun-search-font search-over" style="margin-left:30px;" v-for="(v,i) in searchArr" :key="i">{{generateTitle(v.meta.title)}}</div> -->
			<!-- <div style="display:flex;flex-direction:column"> -->
			<div v-for="(item,i) in searchArr" :key="i" style="margin:5px 0px 0px 20px">
				<a href="javascript:void(0)" class="search-item" :class="{searchHover: item.path?true:false}" @click="jump(item.path)">
					{{item.Text}}
				</a>
			</div>
			<!-- </div> -->
		</div>
		<div style="margin-top:50px">
			<div style="font-size: 16px;"><i class="el-icon-map-location" style="font-size:16px;margin-right: 5px;"></i>搜索全部导航</div>
			<el-tabs v-model="searchNum" style="margin-top:10px">
				<el-tab-pane class="nav-wraper" :label="v.Text" v-for="(v,i) in pageList" :key="i" :name="(i+1)+''">
					<div class="nav-item" v-for="(item, index) in v.ChildMenuDtoList" :key="index">
						<div v-if="item.ChildMenuDtoList && item.ChildMenuDtoList.length">
							<div class="nav-title">{{item.Text}}</div>
							<a href="javascript:void(0)" class="nav-item-child" :class="{navHover: item1.path?true:false}" v-for="(item1, j) in item.ChildMenuDtoList" :key="j"  @click="jump(item1.path)">
								{{item1.Text}}
							</a>
						</div>
						<div v-else>
							<a href="javascript:void(0)" class="nav-item-child" :class="{navHover: item.path?true:false}" @click="jump(item.path)">
								{{item.Text}}
							</a>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</el-dialog>
</template>

<script>
	import {
		generateTitle
	} from '@/utils/i18n';

	import {
		constantRouterMap,
		asyncRouterMap,
	} from '@/router'

	import {
		mapGetters
	} from 'vuex';

	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				searchKeyWords: '',
				searchArr: [],
				searchNum: '1',
				pageList: [],
				issearch:false,
			}
		},
		computed: {
			...mapGetters(['allRouters'])
		},
		created() {
			this.routerList = constantRouterMap.concat(asyncRouterMap);
			this.initData();
		},
		methods: {
			initData() {
				let list = JSON.parse(JSON.stringify(this.allRouters))
				
				list = list.filter(item=>{
					return !item.IsButton && item.Text.indexOf('主播-') < 0 && item.Text.indexOf('店铺-') < 0 && item.Text.indexOf('首页') < 0
				})
				let array = this.filterButton(list);
				this.pageList = array;
			},
			filterButton(list) {
				list.map(item => {

					//过滤按钮、主播、店铺 三种菜单  和首页
					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						item.ChildMenuDtoList = item.ChildMenuDtoList.filter(item => {
							return !item.IsButton && item.Text.indexOf('主播-') < 0 && item.Text.indexOf('店铺-') < 0 && item.Text.indexOf('首页') < 0
						})
					}

					//如果有child则继续递归
					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						item.ChildMenuDtoList = this.filterButton(item.ChildMenuDtoList);
					} else {
						//从路由里查找对应数据
						this.selectPath = '';
						this.getPath(item, this.routerList);
						item.path = this.selectPath
					}

				})
				return list;
			},
			getPath(obj, list) {
				list.map(item => {
					if (item.meta && item.meta.title) {
						if (obj.Sref == item.meta.title) {
							this.selectPath = item.path
						} else {
							if (item.children && item.children.length) {
								this.getPath(obj, item.children)
							}
						}
					}
				})
			},
			jump(path) {
				if (path){
					// console.log(path)
					// var url = location.href.split('#')[0] + '#'+path;
					// window.open(url)
					this.$router.push({
						path:path
					})
				}else{
					this.$message.error('此菜单不可跳转')
				}
			},
			searchKW() {
				this.issearch = true
				this.searchArr = [];
				
				if(this.searchKeyWords){
					this.getSearchData(this.pageList);
				}
				
			},
			getSearchData(list){
				list.map(item=>{
					if (item.Text.indexOf(this.searchKeyWords) > -1){
						if(item.ChildMenuDtoList && item.ChildMenuDtoList.length<=0){
							// console.log(item)
							this.searchArr.push(item)
						}
					}
					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length){
						this.getSearchData(item.ChildMenuDtoList);
					}
				})
			},
			funqueryClose() {
				this.searchArr = []
				this.searchKeyWords = ''
				this.issearch = false
				this.$emit('close')
			}
		}
	}
</script>

<style lang="less" scoped>
	
	.nav-wraper{
		display: flex;
		
		.nav-item{
			flex: 0 0 auto;
			width: 120px;
			
			.nav-title{
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 15px;
				padding-left: 10px;
				height: 30px;
				line-height: 30px;
			}
			.nav-item-child{
				display: block;
				font-size: 13px;
				height: 30px;
				line-height: 30px;
				padding-left: 10px;
				margin-bottom: 5px;
				cursor: pointer;
			}
			.navHover:hover{
				background-color: #ECF5FF;
				color: #409EFF;
			}
		}
	}
	
	.search-item{
		display: block;
		font-size: 13px;
		width: 200px;
		height: 30px;
		line-height: 30px;
		padding-left: 10px;
		margin-bottom: 5px;
		cursor: pointer;
	}
	.searchHover:hover{
		background-color: #ECF5FF;
		color: #409EFF;
	}
	
	.scrollbar {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 30px;
		white-space: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.fun-input ::v-deep .el-input__inner {
		border-radius: 40px;
		margin-top: 15px
	}

	.fun-search-font {
		font-size: 14px;
		margin: 10px 0px 0px 20px
	}

	.search-rul {
		font-size: 16px;
		margin: 20px 0px 0px 20px
	}

	.search-rules-tit {
		/* border:1px solid black; */
		width: 130px;
	}

	.search-rules-titFont {
		font-size: 16px;
	}

	.search-rules-mainFont {
		border: none
	}

	.search-rules-mainFont:hover {
		background: #409eff;
		color: white;
	}

	.announcementFont {
		font-size: 14px;
		width: 220px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.announcementFont:hover {
		color: #409eff
	}

	.search-over {
		font-size: 14px;
	}

	.search-over:hover {
		background: #409eff;
		color: white;
	}
</style>
