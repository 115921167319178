<template>
  <div>

    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix">
        <span>官方体验店铺</span>
      </div>
      <div class="remark1">在您的小程序正式生效之前，您可以去官方体验后台体验所有功能（包含后台功能及小程序），账号为您的登录账号，密码为123456，<el-button type="text"  @click="openNewWindow(8)">去登录</el-button></div>
    </el-card>

    <div class='micro-tooltip' v-if="microTooltipVisible" >
			<i class="el-icon-warning" style="margin-right: 10px;font-size:16px;color:#F56C56;" />
      <div>你的商城小程序还未发布任何版本，发布版本后才能正常使用商城小程序</div>
    </div>

    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix">
        <span>商城小程序</span>
        <el-button type="text" style="margin-left:20px;"  v-if="state!=0" @click='handleUpdate'>更新小程序信息</el-button>
        <div style="float:right">
          <el-button type="text" @click="openNewWindow(1)">小程序绑定教程</el-button>
          <el-button type="text" style="margin-right:10px;" @click="openNewWindow(2)">小程序注册教程</el-button>
        </div>
      </div>

      <template v-if="state==0">
        <div class="card-item">
          <i class="el-icon-link"></i>
          <br>
          <el-button type="primary" style="width:200px" @click="openNewWindow(9)">已有小程序立即绑定</el-button>
          <br>
          <div class="remark">为保证所有功能的正常使用，授权时请把所有权限统一授权给店客易</div>
        </div>
        <div class="card-item" >
          <i class="el-icon-document-copy"></i>
          <br>
          <el-button type="primary" style="width:200px" @click="openNewWindow(3)">没有小程序立即注册</el-button>
          <br>
          <div class="remark">请在微信公众平台申请新的小程序，成功后再到店客易进行绑定</div>
        </div>
      </template>

      <!-- 小程序详细信息 -->
      <div class="program-card" v-if="state!=0">
        <div class='program-info'>
         <label>小程序名称：</label> 
         <span class='info' v-if='mcroData.MicroProgramData.NickName'>{{mcroData.MicroProgramData.NickName}}</span>
         <span class='info' v-else>未设置小程序名称</span>
          <el-button type="text" style="margin-left:20px" @click="openNewWindow(9)">重新授权</el-button>
        </div>
        <div class='program-info'>
          <label>小程序头像：</label>
          <img v-if='mcroData.MicroProgramData.HeadUrl' :src='mcroData.MicroProgramData.HeadUrl' style='width: 64px;height: 64px;object-fit: contain;'>
          <span v-else class='info'>未上传小程序头像</span>
        </div>
        <div class='program-info' style="display:flex;">
          <label>小程序类目：</label>
          <div>
           <!-- 改动 -->
            <div v-for='(t,index) in mcroData.ResultList' :key="index">
              <div v-if='t.SecondCategoryList.length' >
                <div v-for='(tt,index2) in t.SecondCategoryList' :key="index2">
                  {{t.First_class}}-{{tt.Second_class}}</div>
              </div>
              <div v-else>
                {{t.First_class}}
              </div>
            </div>
            <div  class="remark">
              <span>小程序必须添加商家自营-美妆/洗护类目。</span>
              <el-button type='text' class="info-button" @click="openNewWindow(6)">如何添加小程序类目</el-button>
            </div>
          </div>
        </div>
        <div class='program-info'>
          <div>
            <label>小程序秘钥：</label>
            <span class='info'>{{mcroData.MicroProgramData.AppSecret}}</span>
            <el-button type='text'  v-show="!setInputVisible" class="info-button" @click="setInputVisible=true">设置</el-button>
          </div>
          <div v-show="setInputVisible" style="margin-top:10px;margin-left:95px;">
            <el-input v-model="appSecret" placeholder="请输入小程序秘钥" style="width:300px;"></el-input>
            <el-button type='text' class="info-button" @click="setWxAppSecret">确定</el-button>
            <el-button type='text' class="info-button" @click="setInputVisible= false,appSecret= null">取消</el-button>
          </div>
          <div class="remark" style="margin-top:10px;margin-left:95px;">
            <span>小程序秘钥必须设置，未设置小程序秘钥将无法提交小程序版本。</span>
            <el-button type='text' class="info-button"  @click="openNewWindow(7)">如何获取小程序秘钥</el-button>
          </div>
        </div>
        <div class='program-info'>
          <label>发布版本：</label>
          <span class='info' >{{mcroData.MicroProgramData.LatestVersion}}</span>
          <span class='info' style='margin-left:20px;' :style="{'color':mcroData.WxappStatus ==3 ? '#F56C6C':'inherit'}" >{{(mcroData.WxappStatus ==5&&mcroData.MicroProgramData.LatestVersion!=mcroData.MicroProgramData.CurrentVersion)?'待审核':mcroData.WxappStatusDescrt}}</span>
          <el-button type='text' class="info-button" v-if='mcroData.WxappStatus ==1&&mcroData.MicroProgramData.LatestVersion!=mcroData.MicroProgramData.CurrentVersion' @click='handleSubmitCheck(mcroData)'>提交审核</el-button>
          <el-button type='text' class="info-button" v-if='mcroData.WxappStatus ==2' @click='handleBackCheck(mcroData.MicroProgramData.Id)'>撤消审核</el-button>
          <el-button type='text' class="info-button" v-if='mcroData.WxappStatus ==3&&mcroData.MicroProgramData.LatestVersion!=mcroData.MicroProgramData.CurrentVersion' @click='handleSubmitCheck(mcroData)'>重新提交审核</el-button>
          <el-button type='text' class="info-button" v-if='mcroData.WxappStatus ==4' @click='handleRelease(mcroData.MicroProgramData.Id)'>发布上线</el-button>
          <el-button type='text' class="info-button" v-if='mcroData.WxappStatus ==5&&mcroData.MicroProgramData.LatestVersion!=mcroData.MicroProgramData.CurrentVersion' @click='handleSubmitCheck(mcroData)'>提交审核</el-button>
        </div>
        <div class='program-info-error' v-if='mcroData.WxappStatus ==3' >
          失败原因：{{mcroData.MicroProgramData.Reason}}
        </div>
      </div>

    </el-card>
    
    <el-card class="box-card" style="margin-bottom: 100px" v-loading="loading">
      <div slot="header" class="clearfix">
        <span>支付申请</span>
        <div style="float:right">
          <el-button type="text" style="margin-right:10px" @click="openNewWindow(4)">支付申请教程</el-button>
          <el-button type="text" @click="openNewWindow(5)">支付设置教程</el-button>
        </div>
      </div>

      <div class="card-item" v-if="!payApplyList.length">
        <i class="el-icon-coin"></i>
        <br>
        <el-button type="primary" style="width:200px"  @click="openNewTab">申请支付</el-button>
        <br>
        <div class="remark">申请并绑定支付后，商城才可使用在线支付功能</div>
      </div>
      <ApplyPayLine  v-if="payApplyList.length" :payApplyList='payApplyList' :applyState='applyState' @hasSigned='hasSigned'></ApplyPayLine>
    </el-card>
    

    <div class="footer">
			<el-button style="width:240px;" type="primary" @click="handleSave" :loading="saveLoading">保存</el-button>
		</div>
  </div>
</template>

<script>
  import apiList from '@/api/other'
  import {
    mallFreeVersionConfigInfo,
    freeSetWxAppSecret,
    freePayApplyInfo,
    freeJumpValite
  } from '@/api/goods.js'
  import ApplyPayLine from '@/components/ApplyPayLine.vue'
export default {
  components: {
    ApplyPayLine
  },
  data () {
    return {
      microTooltipVisible: false,

      WxSigningQrCode: '',

      loading: false,
      saveLoading: false,
      state: 0,
      data: {
        ExperienceLink: "",
        PassWord: "",
        Phone: "",
        WxAppAuthUrl: ""
      },

      mcroData: {
        MicroProgramData: {}
      },
      appSecret: null,
      setInputVisible: false,

      payApplyList: [],
      applyState: null,
    }
  },
  created () {
    // 验证是否为免费版
    this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
      this.state = window.localStorage.getItem('mlmzFreePayState')
      // 重新获取路由
      this.$store.dispatch('GenerateRoutes').then(() => {
      })
    })
    this.getData()
    this.getMcroDataList()
    this.getPayApplyData()
  },
  methods: {
    async getData() {
      let result = await mallFreeVersionConfigInfo()
      this.data= result.Result
    },
    async getPayApplyData () {
      try {
        this.loading = true
        let result = await freePayApplyInfo()
        this.applyState = result.Result.ApplyState || null
        this.payApplyList = result.Result.RecordList || []
      } catch(e) {
      } finally {
        this.loading = false
      }
    },
    async getMcroDataList() {
      try {
        this.loading = true
        let result = await apiList.mcroProgramList()
        result.forEach(t=> {
          if(t.MicroProgramType == 0 ) {
            this.mcroData = t
          }else {
            this.mcroData = {}
          }
        })
        this.microTooltipVisible = this.state!=0 && !this.mcroData.MicroProgramData.IsPublish
      } catch(e) {
      } finally {
        this.loading = false
      }
    },
    // 更新小程序
    async handleUpdate(){
      try {
        let result = await apiList.mcroProgramUpdate({
          McroProgramId  : this.mcroData.MicroProgramData.Id 
        })
        if(result.IsSuccess){
          this.$message({
            type: 'success',
            message: '更新成功',
            showClose: true
          })
          this.getMcroDataList()
        }
      } catch(e) {
      }
    },
    // 审核小程序
    async handleSubmitCheck(val){
      let firstId = null
      let secondId = null
      val.ResultList.filter(t=> {
        if(t.First_id == 304) {
          t.SecondCategoryList.forEach(tt=> {
            if( tt.Second_id === 786) {
              secondId = tt
            }
          })
          firstId = t
        }
      })
      if(firstId.length&& secondId.length) {
        this.$message({
          type: 'error',
          message: '小程序类目必须添加商城自营-美妆/洗护类目',
          showClose: true
        })
        return false
      }
      let submitAuditModel = {
        Address: val.MicroProgramData.Address ,
        Title: val.MicroProgramData.Title,
        Tag: val.MicroProgramData.Tag,
        FirstId: firstId.First_id,
        FirstClass: firstId.First_class,
        SecondId: secondId.Second_id,
        SecondClass:  secondId.Second_class,
        ThirdId: 0, //固定写死，无作用
        ThirdClass: '' //固定写死，无作用
      }
      try {
        let data = {
          Id: val.MicroProgramData.Id,
          SubmitAuditModel: JSON.stringify(submitAuditModel)
        }
        let result = await apiList.microprogramsubmitaudit(data)
        if(result.IsSuccess){
          this.$message({
            type: 'success',
            message: '审核成功',
            showClose: true
          })
          this.getMcroDataList()
        }
      } catch(e) {
      } finally {
      }
    },
    // 撤销
    handleBackCheck(id) {
      this.$confirm('是否确认撤消小程序审核？1天仅可撤消1次,一个月最多可撤消10次' ,{
        confirmButtonText: '确认撤销',
        cancelButtonText: '取消',
        center: true
      }).then(async() => {
        try {
          let result = await apiList.microprogramrecallcheck({
            Id: id
          })
          if(result.IsSuccess){
            this.$message({
              type: 'success',
              message: '撤销成功',
              showClose: true
            })
            this.getMcroDataList()
          }
        } catch(e) {
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤销'
        });
      });
    },
    // 发布上线
    async handleRelease(id){
        try {
          let result = await apiList.microprogramrelease({
            Id: id,
          })
          if(result.IsSuccess){
            this.$message({
              type: 'success',
              message: '发布成功',
              showClose: true
            })
            this.getMcroDataList()
          }
        } catch(e) {
        }
    },
    // 设置小程序秘钥
    async setWxAppSecret(){
      try {
        let result = await freeSetWxAppSecret({
          AppSecret   : this.appSecret 
        })
        if(result.IsSuccess){
          this.$message({
            type: 'success',
            message: '设置成功',
            showClose: true
          })
          this.appSecret = ''
          this.setInputVisible = false
          this.getMcroDataList()
        }
      } catch(e) {
      }
    },
    openNewWindow(index) {
      switch (index){
        case 1://小程序绑定教程
            window.open('https://www.yuque.com/docs/share/7903ee5e-c7cf-4062-ab31-9117fa2e9146?#')
            break;
        case 2://小程序注册教程
            window.open('https://www.yuque.com/docs/share/4bfc8bf9-7a5d-4f27-97e9-e32e66d221af?#')
            break;
        case 3://没有小程序立即注册
            window.open('https://mp.weixin.qq.com')
            break;
        case 4://支付申请教程
            window.open('https://www.yuque.com/docs/share/5102bedb-c65a-457a-b9df-f89c92144fae?#')
            break;
        case 5://支付设置教程
            window.open('https://www.yuque.com/docs/share/cf866ced-fd7a-4b76-a91d-ef83739c0af3?#')
            break;
        case 6://如何添加小程序类目
            window.open('https://www.yuque.com/docs/share/7ec22614-9e93-41d3-832d-0c98dffa6ca2?#')
            break;
        case 7://如何获取小程序秘钥
            window.open('https://www.yuque.com/docs/share/0dfa1d84-5f8f-450f-8ae2-70e70c146f88?#')
            break;
        case 8://去登陆
            window.open(this.data.ExperienceLink)
            break;
        case 9://已有小程序立即绑定
            window.open(this.data.WxAppAuthUrl)
            break;
      }

    },
    openNewTab(){
      // 点击申请，再次验证免费商户是否设置了小程序
      this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
        this.state = window.localStorage.getItem('mlmzFreePayState')
        if(this.state== 0) {
          this.$message({
            message: '请先绑定商城小程序',
            type: 'error',
            showClose: true
          })
          return 
        }
        if(this.state== 3) {
          this.$message({
            message: '请先设置小程序密钥',
            type: 'error',
            showClose: true
          })
          return 
        }
        window.localStorage.setItem('canEditSetinfos', true)
        window.localStorage.setItem('toPayResultFromPath', this.$route.path)
        this.$router.push({
          path: '/setting/setPayApply'
        })
      })
    },
    hasSigned() {
      this.getPayApplyData()
    },
    async handleSave() {
      try {
        let result = await freeJumpValite()
        if(result.IsSuccess){
          this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
            // 重新获取路由
            this.$store.dispatch('GenerateRoutes').then(() => {
              location.reload()
            })
          })
        }
      } catch(e) {}
    }
  }
}
</script>

<style lang='less' scoped>
.micro-tooltip {
  padding: 10px;
  font-size: 13px;
  background: #FDE2E2;
  color:#ff5566;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ff5566;
}
.box-card {
  margin-bottom: 20px;
  .remark1 {
    margin-left: 100px;
    font-size: 13px;
    margin-top: 5px;
  }
  ::v-deep .el-card__header{
    padding: 10px 20px;
  }
  ::v-deep .el-card__body {
    display: flex;
    flex-direction: row;
  }
  .clearfix {
    line-height: 36px;
  }
  .card-item1 {
    display: inline-block;
    margin-left: 100px;
    width: 400px;
    .remark {
      color: #909399;
      font-size: 13px;
      margin-top: 5px;
    }
  }
  .card-item {
    display: inline-block;
    margin-left: 100px;
    width: 400px;
    height: 200px;
    flex: 0 0 auto;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    i {
      color: #409EFF;
      font-size: 55px;
      margin-bottom: 5px;
    }
    .remark {
      color: #909399;
      font-size: 13px;
      margin-top: 5px;
    }
  }
}
  .program-card{
    margin-top: 10px;
    margin-left: 100px;
    .program-info-error{
      color: #F56C6C;
      margin-left: 20px;
      font-size: 15px;
    }
    .program-info{
      margin-bottom: 20px;
      label{
        text-align: right;
        display: inline-block;
        font-size: 14px;
        color: #606266;
        font-weight: 700;
        width: 90px;
        margin-right: 5px;
      }
      .info {
        font-size: 15px;
      }
      img{
        vertical-align: top;
      }
      .info-button {
        margin-left: 10px;
      }
      .remark {
        font-size: 13px;
        color: #909399;
        margin-top: 10px;
      }
    }
  }

.footer {
  box-shadow: 13px 1px 6px #999;
  line-height: 80px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  z-index: 9;
}
</style>