<template>
	<div :class="className" :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
	import echarts from 'echarts'
	import resize from './mixins/resize'

	export default {
		mixins: [resize],
		props: {
			className: {
				type: String,
				default: 'chart'
			},
			id: {
				type: String,
				default: 'chart'
			},
			width: {
				type: String,
				default: '100%'
			},
			height: {
				type: String,
				default: '500px'
			},
			info: {
				type: Object,
				default: () => {
					return {};
				}
			}
		},
		data() {
			return {
				chart: null,
				datainfo: {},
			}
		},
		watch: {
			info(val) {
				this.datainfo = val
				this.initChart()
			}
		},
		mounted() {},
		beforeDestroy() {
			if (!this.chart) {
				return
			}
			this.chart.dispose()
			this.chart = null
		},
		methods: {
			initChart() {
				this.chart = echarts.init(document.getElementById(this.id))
				this.chart.setOption({
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// '支付金额', 
						data: ['支付金额', '支付订单数']
					},
					grid: {
						 bottom:50
						// left: '3%',
						// right: '4%',
						// bottom: '3%',
						// containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.datainfo.DateList
					},
					yAxis: [
						{
							name: '支付金额',
							type: 'value',
						},
						{
							name: '支付订单数',
							type: 'value',
						}
					],
					series: [{
							name: '支付金额',
							lineStyle: {
								width: 1
							},
							animation: false,
							type: 'line',
							data: this.datainfo.MoneyList
						},
						{
							name: '支付订单数',
							type: 'line',
							lineStyle: {
								width: 1
							},
							yAxisIndex: 1,
							animation: false,
							data: this.datainfo.CountList
						},
					]
				}, true)
			}
		},
	}
</script>
