<template>
  <div>
    <freeHome v-if="isFreeMallNoSet"></freeHome>
    <mallHome v-else></mallHome>
  </div>
</template>

<script>
import mallHome from './mallHome.vue'
import freeHome from './freeHome.vue'
import zhLangApi from '@/lang/zh.js';
export default {
  components: {
    mallHome,
    freeHome
  },
  data () {
    return {
      isFreeMallNoSet: false,  
    }
  },
  created () {
    this.isFreeMallNoSet = window.localStorage.getItem('mlmzFreePayState')!=2 && window.localStorage.getItem('mlmzIsFree')== 'true' 
    zhLangApi.route.homeTitle = this.isFreeMallNoSet ? '配置面板':'实时概览'
  }
}
</script>

<style>

</style>